import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { LatLngLiteral } from 'leaflet'

import { RejectValue } from 'src/libs/error.handler'
import eventEmitter from 'src/libs/event.emitter'

import BaseButton from 'src/components/buttons/BaseButton'
import GradientButton from 'src/components/buttons/GradientButton'
import BaseDialog, { BaseDialogProps } from 'src/components/dialogs/BaseDialog'
import BaseRoundedInput from 'src/components/inputs/BaseRoundedInput'

import { AppDispatch } from 'src/store'
import { saveLocationAction } from 'src/store/home'

type Props = { location: LatLngLiteral } & BaseDialogProps

function EmailDialog({ location, onClose, ...others }: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const [email, setEmail] = useState('')
  const [{ errors }, setError] = useState<RejectValue>({})

  const handleChangeEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value),
    [],
  )
  const handleSubmit = useCallback(() => {
    dispatch(saveLocationAction({ location, email }))
  }, [email, location])

  useEffect(() => {
    const onSuccess = () => onClose?.()
    const onError = (error: RejectValue) => {
      setError(error)
    }
    eventEmitter.on(saveLocationAction.fulfilled.type, onSuccess)
    eventEmitter.on(saveLocationAction.rejected.type, onError)

    return () => {
      eventEmitter.off(saveLocationAction.fulfilled.type, onSuccess)
      eventEmitter.off(saveLocationAction.rejected.type, onError)
    }
  }, [])

  return (
    <BaseDialog
      className='flex w-full max-w-md flex-col gap-4 rounded-lg'
      onClose={onClose}
      {...others}
    >
      <h4>Confirm Location</h4>
      <div>
        <div>lat: {location.lat}</div>
        <div>lng: {location.lng}</div>
      </div>
      <div className='flex flex-col gap-1'>
        <label htmlFor='location-email'>Email *</label>
        <BaseRoundedInput
          id='location-email'
          className='rounded-md'
          inputClassName='h-9 text-sm'
          autoComplete='off'
          value={email}
          type='email'
          name='email'
          onChange={handleChangeEmail}
        />
        {errors?.email && <span className='text-error'>{errors.email[0].message}</span>}
      </div>
      <div className='flex justify-end gap-4'>
        <BaseButton className='rounded-full' size='sm' onClick={onClose}>
          Close
        </BaseButton>
        <GradientButton className='rounded-full' size='sm' onClick={handleSubmit} disabled={!email}>
          Submit
        </GradientButton>
      </div>
    </BaseDialog>
  )
}

export default EmailDialog
