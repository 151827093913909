import { useMapEvents } from 'react-leaflet'
import { LatLngLiteral, LeafletMouseEvent } from 'leaflet'

type Props = {
  onSelect: (location: LatLngLiteral) => void
}

function LocationSelector({ onSelect }: Props) {
  useMapEvents({
    click(e: LeafletMouseEvent) {
      const { lat, lng } = e.latlng
      onSelect({ lat, lng })
    },
  })
  return null
}

export default LocationSelector
