import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LatLngLiteral } from 'leaflet'

import errorHandler from 'src/libs/error.handler'

import { RootState } from 'src/store'

import { getLocations, saveLocation } from 'src/apis/home.api'

export type AuthState = {
  locations: LatLngLiteral[]
}

const initialState: AuthState = {
  locations: [],
}

export const getLocationsAction = createAsyncThunk('home/getLocations', errorHandler(getLocations))
export const saveLocationAction = createAsyncThunk('home/saveLocation', errorHandler(saveLocation))

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getLocationsAction.fulfilled, (state, action) => {
        state.locations = action.payload
      })
      .addCase(saveLocationAction.fulfilled, (state, action) => {
        state.locations.push(action.meta.arg.location)
      })
  },
})

export const selectLocations = (state: RootState) => state.home.locations

const homeReducer = homeSlice.reducer
export default homeReducer
