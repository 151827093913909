import { LatLngLiteral } from 'leaflet'

import axios from 'src/libs/axios'

export const getLocations = async (_: void) => {
  return axios.get<LatLngLiteral[]>('/api/locations').then((res) => res.data)
}

type SaveLocationReq = {
  location: LatLngLiteral
  email: string
}

export const saveLocation = async (payload: SaveLocationReq) => {
  return axios.post<void>('/api/locations', payload).then((res) => res.data)
}
