import React from 'react'
import { Outlet } from 'react-router-dom'

import DefaultFooter from 'src/layouts/Default/Footer'
import DefaultHeader from 'src/layouts/Default/Header'

function DefaultLayout() {
  return (
    <div className='flex min-h-screen flex-col'>
      <DefaultHeader className='flex-none' />
      <div className='flex flex-auto'>
        <div className='w-full'>
          <Outlet />
        </div>
      </div>
      <DefaultFooter className='flex-none' />
    </div>
  )
}

export default DefaultLayout
