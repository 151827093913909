import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import L, { LatLngLiteral } from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import 'leaflet/dist/leaflet.css';

import EmailDialog from 'src/pages/Home/components/EmailDialog';
import LocationSelector from 'src/pages/Home/components/LocationSelector';

import { AppDispatch } from 'src/store';
import { getLocationsAction, selectLocations } from 'src/store/home';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
L.Marker.prototype.options.icon = DefaultIcon;

function HomePage() {
  const dispatch = useDispatch<AppDispatch>();
  const locations = useSelector(selectLocations);
  const [selectedLocation, setSelectedLocation] = useState<LatLngLiteral>();

  useEffect(() => {
    dispatch(getLocationsAction());
  }, [dispatch]);

  const locationsWithCount = useMemo(() => {
    const gridSize = 0.5; // Approximately 50 km
    const acc: { [pos: string]: { count: number } & LatLngLiteral } = {};

    locations.forEach((curr) => {
      const gridX = Math.floor(curr.lat / gridSize);
      const gridY = Math.floor(curr.lng / gridSize);
      const key = `${gridX},${gridY}`;

      if (!acc[key]) {
        acc[key] = {
          lat: gridX * gridSize + gridSize / 2,
          lng: gridY * gridSize + gridSize / 2,
          count: 1,
        };
      } else {
        acc[key].count++;
      }
    });

    return Object.values(acc);
  }, [locations]);

  const handleLocationSelect = useCallback((location: LatLngLiteral) => {
    setSelectedLocation(location);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setSelectedLocation(undefined);
  }, []);

  return (
    <>
       <div className="instruction-card">
        <ol>
          <li>Use the map to see how many Gunbotters have signed up in your area (within 100 km).</li>
          <li>Click on your location to sign up with your email.</li>
          <li>We'll group you with others nearby and send detailed instructions and the program to your email.</li>
        </ol>
      </div>

      <MapContainer center={[0, 0]} zoom={2} className="z-10 h-full w-full">
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <LocationSelector onSelect={handleLocationSelect} />
        {locationsWithCount.map((loc, index) => {
          const icon = L.divIcon({
            className: 'custom-icon',
            html: `<div class="user-count">${loc.count}<span class="exponent"> Gunbotter${loc.count > 1 ? 's' : ''}</span></div>`,
          });
          return (
            <Marker key={index} position={[loc.lat, loc.lng]} icon={icon}>
              <Popup>
                {loc.count} Gunbotter{loc.count > 1 ? 's' : ''} signed up in this area
              </Popup>
            </Marker>
          );
        })}
        {selectedLocation && (
          <Marker position={[selectedLocation.lat, selectedLocation.lng]}>
            <Popup>A flag has been placed here.</Popup>
          </Marker>
        )}
      </MapContainer>
      {selectedLocation && (
        <EmailDialog open location={selectedLocation} onClose={handleCloseDialog} />
      )}
    </>
  );
}

export default HomePage;
