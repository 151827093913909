import React from 'react'
import clsx from 'clsx'

import { BaseDivProps } from 'src/types'

function DefaultHeader({ className, ...others }: BaseDivProps) {
  return (
    <div className={clsx('flex items-center gap-8 px-6 py-3', className)} {...others}>
      <h4>Gunbot Community: Knowledge, Trading, Wealth</h4>
    </div>
  )
}

export default DefaultHeader
