import React from 'react'
import clsx from 'clsx'

import { BaseDivProps } from 'src/types'

function DefaultFooter({ className, ...others }: BaseDivProps) {
  return <div className={clsx('', className)} {...others}></div>
}

export default DefaultFooter
