import React, { useEffect } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

import IconButton from 'src/components/buttons/IconButton'

import { BaseDivProps } from 'src/types'

export type BaseDialogProps = {
  open?: boolean
  onClose?: () => void
  backdrop?: boolean
} & BaseDivProps

function BaseDialog({ open, className, onClose, backdrop, children }: BaseDialogProps) {
  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [open])

  return (
    <div
      className={clsx(
        'pointer-events-none fixed inset-0 z-50 hidden h-full w-full overflow-y-auto px-4',
        {
          '!flex': open,
          'bg-black bg-opacity-90 ': backdrop,
        },
      )}
    >
      <div
        className={clsx(
          'pointer-events-auto relative m-auto bg-mainBg p-6 shadow-xl sm:p-8 lg:p-[46px]',
          className,
        )}
      >
        <IconButton
          onClick={onClose}
          className='absolute right-[17px] top-[24px] h-[30px] w-[30px] cursor-pointer'
        >
          <XMarkIcon />
        </IconButton>
        {children}
      </div>
    </div>
  )
}

export default BaseDialog
